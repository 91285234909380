<template>
	<div v-if="authed">
		<div style="max-height: 100%" class="overflow-y-auto">
			<v-data-table :headers="headers" :items="items" item-key="_id"
				:options.sync="options" :server-items-length="totalItems"
				:loading="loading" loading-text="加载中...">
				<template v-slot:top>
					<div class="d-flex flex-wrap pt-4 pb-2">
						<v-btn class="ml-2" color="primary" @click.stop="addItem">添加条目</v-btn>
						<v-select placeholder="分类" :items="itemclss" v-model="itemcls" hide-no-data hide-details dense clearable
							style="flex: 0 1 auto" class="ml-6" @change="loadData"/>
						<v-text-field v-model.trim="pname" label="查询" dense hide-details style="flex: 0 1 auto" class="ml-6"
							clearable append-icon="search" @click:append="loadData" @change="loadData"/>
					</div>
					<v-divider/>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-icon class="mr-2" @click.stop="editItem(item)">mdi-square-edit-outline</v-icon>
					<v-icon class="mr-2" @click.stop="deleteItem(item)">mdi-close</v-icon>
				</template>
			</v-data-table>
		</div>
        <v-dialog persistent eager v-model="dialog" max-width="560">
			<v-card>
				<v-card-title>条目</v-card-title>
				<v-card-text>
					<v-form ref="form">
						<v-container>
							<v-row>
								<v-col cols="12"><v-text-field label="名称" v-model.trim="ci.项目名称" dense :rules="notnullRules"/></v-col>
							</v-row>
							<v-row>
								<v-col cols="4"><v-select label="分类" v-model="ci.项目分类" :items="itemclss" dense :rules="mustselectRules"/></v-col>
								<v-col cols="4"><v-text-field label="编码" v-model.trim="ci._id" dense :rules="notnullRules"/></v-col>
								<v-col cols="4"><v-text-field label="拼音码" v-model.trim="ci.拼音码" dense :rules="notnullRules"/></v-col>
							</v-row>
							<v-row>
								<v-col cols="6"><v-text-field label="规格" v-model="ci.规格" dense/></v-col>
								<v-col cols="3"><v-autocomplete label="单位" v-model="ci.单位" :items="itemunits" dense :rules="mustselectRules"/></v-col>
								<v-col cols="3"><v-autocomplete label="拆零单位" v-model="ci.拆零单位" :items="itemunits" dense/></v-col>
							</v-row>
							<v-row>
								<v-col cols="6"><v-text-field label="基本剂量" v-model.number="ci.基本剂量" dense/></v-col>
								<v-col cols="3"><v-autocomplete label="剂量单位" v-model="ci.剂量单位" :items="itemdoses" dense/></v-col>
								<v-col cols="3"><v-text-field label="拆零转换率" v-model.number="ci.转换率" dense/></v-col>
							</v-row>
							<v-row>
								<v-col cols="6"><v-text-field label="生产厂家" v-model.trim="ci.生产厂家" dense/></v-col>
								<v-col cols="6"><v-text-field label="备注" v-model.number="ci.备注" dense/></v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click.stop="saveItem" :loading="loading" :disabled="loading">保存</v-btn>
					<v-btn @click.stop="dialog = false">取消</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
    export default {
        name: 'ItemMan',
        data() {
            return {
                authed: false,
                headers: [
                    {text:'操作', value:'actions', width:100, sortable: false},
					{text:'编码', value:'_id', width:150},
                    {text:'分类', value:'项目分类', width:110},
					{text:'名称', value:'项目名称', width:240},
					{text:'拼音码', value:'拼音码', width:150},
					{text:'规格', value:'规格', width:150},
					{text:'单位', value:'单位', width:100},
					{text:'拆零单位', value:'拆零单位', width:100},
					{text:'转换率', value:'转换率', width:100},
					{text:'基本剂量', value:'基本剂量', width:120},
					{text:'剂量单位', value:'剂量单位', width:100},
					{text:'生产厂家', value:'生产厂家', width:180},
					{text:'备注', value:'备注', width:180},
                ],
                items: [],
                loading: false,
				options: {},
				totalItems: 0,
                selected: [],
				itemclss: [
					'检查类',
					'西药类',
					'中药类',
					'挂号类',
					'床位类',
					'手术类',
					'治疗类',
					'护理类',
					'化验类',
					'其他费用类',
					'耗材类'
				],
				itemcls: '',
				itemunits: [
					'盒','瓶','包','桶','筒','板','袋','中盒','个','支','付','份','贴','枚','本','片','具','对','粒','丸','条','毫居','g','mg','kg','ml','次','例','张',
					'根','卷','块','部位','小时','套','天','餐','罐','株','单侧','听','双侧','颗','把','台','捆','箱','全口','野','点','半小时','项'
				],
				itemdoses: [
					'g','mg','ml','ug','L','万单','MIU','u','万IU','kiu','gm','MIC','滴','公斤','kg','毫居','iu','片','AXU','筒','贴','PE','条',"BU",'喷','对',
					'Axa','mci','KU','袋','个','付','具','丸','包','贴','支','盒','T','瓶','PNA','粒','* 1.5 mg','mbq','单位','吸'
				],
				dialog: false,
				newitem: false,
				ci: {},
				notnullRules: [v => !!v || '此项为必填'],
				mustselectRules: [v => (!!v && !!v.length) || '此项为必填'],
				pname: '',
            }
        },
		mounted() {
			this.authed = this.$hasPrivilege('条目管理');
			if (!this.authed) return;
		},
		watch: {
			options: {
				handler() {
					this.fetchData()
				},
				deep: true,
			},
		},
		methods: {
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				this.loading = true;
				try {
					const db = this.$tcbapp.database();
					const _ = db.command;
					const f = [{'_id':_.exists(true)}];
					if (this.pname) {
						const filter = _.or([{
							'拼音码': db.RegExp({
								regexp: this.pname,
								options: 'i'
							})}, {
							'项目名称': db.RegExp({
								regexp: this.pname,
								options: 'i'
							})
						}]);
						f.push(filter);
					}
					if (this.itemcls) {
						f.push({'项目分类':this.itemcls});
					}
//					f.push({'产地':'*'});
                    const countRes = await db.collection('kbjitems').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('kbjitems').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
            async addItem() {
				this.ci = {'产地':'*'};
            	this.dialog = true;
				this.$refs.form.reset();
				this.newitem = true;
            },
            async editItem(item) {
				this.ci = Object.assign({}, item);
                this.dialog = true;
				this.newitem = false;
            },
			async deleteItem(item) {
				const res = await this.$dialog.confirm({
					text: '确定要删除此条目？',
					title: '提示'
				});
				if (!res) return;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'deleteItem',
						data:{
							_id: item._id,
						}
					}});
					await this.fetchData();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				}
			},
			async saveItem() {
				this.$refs.form.validate();
				if (!this.$refs.form.validate()) return;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'setItem',
						data: this.ci,
					}});
					this.$dialog.message.success('保存完成');
					await this.fetchData();
	            	this.dialog = false;
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('保存失败');
				}
			}
		}
    }
</script>
